import React, { useState, useContext, useEffect, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { NavBar, Tabs, List, Tag, Badge, Skeleton, ErrorBlock, Image } from 'antd-mobile';
import get from 'lodash/get';
import styled from 'styled-components';
import { getAircraft } from '../api';
import { store } from '../utils/store';
import { getAircraftStatusData } from '../utils/helpers';
import { featureEnabled, matchesPermission } from '../utils/permission-helpers';
import Logbook from './Logbook';
import AircraftInfoComopnent from '../components/AircraftInfo';
import Defects from './Defects';
import Bookings from './Bookings';

const AircraftWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;
const ContentWrapper = styled.div`
	flex: 1;
	min-height: 0;
	overflow:  auto;
	width: 100%;
	background: #f0f2f5;
`;

const Status = styled(Tag)`
`;

const AircraftImage = styled(Image)`
	padding: 18px 8px 0px 0px;
`;

const AircraftInfo = styled.div`
	padding: 0px 12px;
	background: #fff;

	.adm-list-item-title {
		display: flex;
		align-items: center;
	}

	h2 {
		margin: 0px 8px 0px 0px;
		display: inline-block;
		color: var(--adm-color-text);
	}

	.adm-ellipsis {
		a {
			margin-left: 8px;
		}
	}
`;

const NotFound = styled(ErrorBlock)`
	height: 100%;
	padding: 12px;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
`;

const Nav = styled(NavBar)`
	background: #fff;
	display: flex;
	border-bottom: solid 1px var(--adm-color-border);
`;

const TabBar = styled(Tabs)`
	display: flex;
	flex-direction: column;
	height: 100%;
	.adm-tabs-header {
		background: #fff;
	}

	.adm-tabs-content {
		flex: 1;
		overflow:  auto;
		padding: 0px;
	}
`;

function Aircraft() {
	const params = useParams();
	const history = useHistory();
	const { id, path } = params;
	const globalState = useContext(store).state;
	const { userSelf } = globalState;
	const [loading, setLoading] = useState(false);
	const [err, setErr] = useState(false);
	const [aircraft, setAircraft] = useState({});

	async function fetchAircraft() {
		setLoading(true);
		try {
			const data = await getAircraft(id);
			setAircraft(data);
		} catch (e) {
			setErr(true);
		}
		setLoading(false);
	}


	useEffect(() => {
		fetchAircraft();
	}, []);

	function getAlertDefectsCount() {
		const totalPastDue = get(aircraft.stats, 'defects_past_due_count');
		const totalNearDue = get(aircraft.stats, 'defects_near_due_count');

		const totalOpen = get(aircraft.stats, 'defects_open_count') || 0;

		const totalDue = totalPastDue + totalOpen;

		return totalDue + totalNearDue;
	}

	return (
		err ? <NotFound
			title="Aircraft not found"
			description="This aircraft not exist, or you dont have rights to view it"
			status='empty'
		/> : <AircraftWrapper>
			<Nav onBack={() => history.push('/')} back={''}>
				Aircraft
			</Nav>
			<AircraftInfo>
				{loading ?
					<Fragment>
						<Skeleton.Title animated/>
						<Skeleton.Paragraph lineCount={1} animated/>
					</Fragment> :
					<List.Item
						key={aircraft.id}
						title={<Fragment>
							<h2>{aircraft.registration || '-'}</h2>
							<Status color={getAircraftStatusData(aircraft.status).color}>
								{getAircraftStatusData(aircraft.status).text}
							</Status>
						</Fragment>}
						prefix={
							<AircraftImage
								src={`${process.env.API_URL}${aircraft.img}`}
								fit='cover'
								width={64}
								height={64}
							/>
						}
						description={aircraft.serial}
					>
						{aircraft.model}
					</List.Item>}
			</AircraftInfo>
			<ContentWrapper>
				<TabBar onChange={key => history.push(`/aircraft/${id}/${key}`)} activeKey={path || 'info'}>
					<Tabs.Tab
						title="Info"
						key="info"
					>
						<AircraftInfoComopnent
							onAircraftChange={fetchAircraft}
							loading={loading}
							aircraft={aircraft}
						/>
					</Tabs.Tab>
					{matchesPermission(userSelf, 'view_aircraft_bookings') && <Tabs.Tab
						title="Bookings"
						key="bookings"
					>
						<Bookings
							flightloggerEnabled={get(aircraft, 'flightlogger.sync_statuses')}
							aircraftId={aircraft.id}
						/>
					</Tabs.Tab>}
					{featureEnabled(userSelf, 'utilization_enabled') && matchesPermission(userSelf, 'view_utilization') && <Tabs.Tab
						title="Logbook"
						key="logbook"
					>
						<Logbook
							flightloggerEnabled={get(aircraft, 'flightlogger.sync_statuses')}
							aircraftId={aircraft.id}
						/>
					</Tabs.Tab>}
					{matchesPermission(userSelf, 'view_aircraft_defects') &&
						<Tabs.Tab
							title={
								<Badge content={getAlertDefectsCount() || null} style={{ '--right': '-10px', '--top': '4px' }}>
									Defects
								</Badge>
							}
							key="defects"
						>
							<Defects aircraft={aircraft} />
						</Tabs.Tab>}
				</TabBar>
			</ContentWrapper>
		</AircraftWrapper>
	);
}

export default Aircraft;

import axios from 'axios';

export const login = async (payload) => {
	const { data } = await axios({
		method: 'post',
		url: `${process.env.API_URL}/auth/login`,
		data: payload
	});

	return data;
};

export const logout = async () => {
	const { data } = await axios.get(`${process.env.API_URL}/auth/logout`, { withCredentials: true });
	return data;
};

export const getUserSelf = async () => {
	const { data } = await axios.get(`${process.env.API_URL}/api/self`, { withCredentials: true });
	return data;
};

export const getAircrafts = async (query = '') => {
	const { data } = await axios.get(`${process.env.API_URL}/api/aircrafts${query}`, { withCredentials: true });
	return data;
};
export const getAircraft = async (id) => {
	const { data } = await axios.get(`${process.env.API_URL}/api/aircraft/${id}`, { withCredentials: true });
	return data;
};

export const updateAircraft = async (aircraftId, payload) => {
	const { data } = await axios({
		method: 'put',
		url: `${process.env.API_URL}/api/aircraft/${aircraftId}`,
		data: payload,
		withCredentials: true
	});

	return data;
};

export const getAircraftDefects = async (aircraftId, query) => {
	const { data } = await axios.get(`${process.env.API_URL}/api/aircraft/${aircraftId}/defects/${query}`, { withCredentials: true });
	return data;
};

export const getAircraftDefect = async (aircraftId, id) => {
	const { data } = await axios.get(`${process.env.API_URL}/api/aircraft/${aircraftId}/defect/${id}`, { withCredentials: true });
	return data;
};

export const addAircraftDefect = async (aircraftId, payload) => {
	const { data } = await axios({
		method: 'post',
		url: `${process.env.API_URL}/api/aircraft/${aircraftId}/defects`,
		data: payload,
		withCredentials: true
	});

	return data;
};

export const updateAircraftDefect = async (aircraftId, payload) => {
	const { data } = await axios({
		method: 'put',
		url: `${process.env.API_URL}/api/aircraft/${aircraftId}/defect/${payload.id}`,
		data: payload,
		withCredentials: true
	});

	return data;
};

export const deleteAircraftDefect = async (aircraftId, id) => {
	const { data } = await axios({
		method: 'delete',
		url: `${process.env.API_URL}/api/aircraft/${aircraftId}/defect/${id}`,
		data: {},
		withCredentials: true
	});

	return data;
};

export const updateFlightLogItem = async (aircraftId, payload) => {
	const { data } = await axios({
		method: 'put',
		url: `${process.env.API_URL}/api/aircraft/${aircraftId}/utilization/${payload.id}`,
		data: payload,
		withCredentials: true
	});

	return data;
};

export const getFlightLogItem = async (aircraftId, id) => {
	const { data } = await axios.get(`${process.env.API_URL}/api/aircraft/${aircraftId}/utilization/${id}`, { withCredentials: true });
	return data;
};

export const getFlightLog = async (aircraftId, query = '') => {
	const { data } = await axios.get(`${process.env.API_URL}/api/aircraft/${aircraftId}/utilization${query}`, { withCredentials: true });
	return data;
};

export const addFlightLog = async (aircraftId, payload) => {
	const { data } = await axios({
		method: 'post',
		url: `${process.env.API_URL}/api/aircraft/${aircraftId}/utilization`,
		data: payload,
		withCredentials: true
	});

	return data;
};

export const deleteFlightLog = async (aircraftId, id) => {
	const { data } = await axios({
		method: 'delete',
		url: `${process.env.API_URL}/api/aircraft/${aircraftId}/utilization/${id}`,
		data: {},
		withCredentials: true
	});

	return data;
};

export const getAircraftChecklists = async (query = '') => {
	const { data } = await axios.get(`${process.env.API_URL}/api/aircraft-checklists${query}`, { withCredentials: true });
	return data;
};

export const getList = async (objectsType, query = '') => {
	const { data } = await axios.get(`${process.env.API_URL}/api/list/${objectsType}${query}`, { withCredentials: true });
	return data;
};


export const getFiles = async (query = '') => {
	const { data } = await axios.get(`${process.env.API_URL}/api/files/${query}`, { withCredentials: true });
	return data;
};

export const getNotes = async (query = '') => {
	const { data } = await axios.get(`${process.env.API_URL}/api/notes/${query}`, { withCredentials: true });
	return data;
};

export const uploadFile = async (query, formData) => {
	const { data } = await axios({
		method: 'post',
		url: `${process.env.API_URL}/api/files/${query}`,
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		withCredentials: true
	});

	return data;
};


export const createOrEditNote = async (payload) => {
	if (payload.id) {
		const { data } = await axios({
			method: 'put',
			url: `${process.env.API_URL}/api/note/${payload.id}`,
			data: payload,
			withCredentials: true
		});

		return data;
	}


	const { data } = await axios({
		method: 'post',
		url: `${process.env.API_URL}/api/notes`,
		data: payload,
		withCredentials: true
	});

	return data;
};

export const deleteNote = async (id) => {
	const { data } = await axios({
		method: 'delete',
		url: `${process.env.API_URL}/api/note/${id}/`,
		data: {},
		withCredentials: true
	});

	return data;
};

import React, { useContext, Fragment } from 'react';
import { List, Ellipsis, SpinLoading, ErrorBlock, Tag } from 'antd-mobile';
import get from 'lodash/get';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { store } from '../utils/store';
import { getStatusData } from '../utils/helpers';

const Defects = styled(List)`
	width: 100%;
	padding: 12px 0px;
	.adm-list-item-content-extra {
		display: flex;
	}
`;


const SpinWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`;

const Status = styled(Tag)`
`;

function DefectsList(props) {
	const {
		defects,
		loading
	} = props;
	const globalState = useContext(store).state;
	const history = useHistory();
	const { userSelf } = globalState;

	function getList() {
		if (!defects.length) {
			return <ErrorBlock
				style={{ paddingTop: 12 }}
				title="No defects found"
				description=""
				status='empty'
			/>;
		}

		return <Defects>
			{defects.map((defect) => {
				const status = getStatusData(defect.status);

				return <List.Item
					extra={<Status color={status.color}>
						{status.text}
					</Status>}
					title={<Fragment>
						<span>{`${defect.date ? moment(defect.date).format(get(userSelf.settings, 'date_format')) : ''}`}</span>
						{defect.user_name && defect.date && <span> &middot; </span>}
						{defect.user_name && <span>{defect.user_name}</span>}
					</Fragment>}
					key={defect.id}
					description={<Ellipsis direction='end' rows={3} content={defect.reference || '-'} />}
					onClick={() => history.push(`/aircraft/${defect.aircraft_id}/defect/${defect.id}`)}
					clickable
				>
					<Ellipsis direction='end' rows={3} content={defect.description || '-'} />
				</List.Item>;
			})}
		</Defects>;
	}

	return (
		loading ? <SpinWrapper><SpinLoading style={{ '--size': '48px' }} /></SpinWrapper> : getList()
	);
}

export default DefectsList;

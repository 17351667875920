/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { NavBar, List, Popup, Button } from 'antd-mobile';
import { CloseOutline } from 'antd-mobile-icons';
import moment from 'moment';
import get from 'lodash/get';
import styled from 'styled-components';
import { store } from '../utils/store';
import InputComponent from './Input';
import { getTrackingParameters, isEnabledParameter } from '../utils/helpers';
import { SQL_DATE_FORMAT } from '../utils/constants';

const DefectWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;
const ContentWrapper = styled.div`
	flex: 1;
	min-height: 0;
	overflow: auto;
	width: 100%;
	background: #f0f2f5;
`;

const Overview = styled.div`
	padding: 12px 0px;
`;

const Nav = styled(NavBar)`
	background: #fff;
	display: flex;
	border-bottom: solid 1px var(--adm-color-border);
`;

const Save = styled(Button)`
	font-size: 17px;
`;

function DefectPopup(props) {
	const {
		defect = {},
		visible,
		saving,
		onClose,
		aircraft = {},
		onSave
	} = props;
	const globalState = useContext(store).state;
	const { userSelf } = globalState;

	const TRACKING_PARAMETERS = getTrackingParameters(userSelf);

	const [values, setValues] = useState({
		date: moment().format(SQL_DATE_FORMAT),
		near_due_days: 30,
		...defect
	});

	function onSaveClick() {
		onSave(values);
	}

	useEffect(() => {
		setValues({
			date: moment().format(SQL_DATE_FORMAT),
			near_due_days: 30,
			...defect
		});
	}, [visible]);

	async function onValueChange(data) {
		setValues({ ...values, ...data });
	}

	return (
		<Popup
			position='right'
			visible={visible}
			bodyStyle={{ width: '100%' }}
			onClose={onClose}
		>
			<DefectWrapper>
				<Nav
					backArrow={<CloseOutline />}
					onBack={onClose}
					back={''}
					right={<Save fill='none' loading={saving} onClick={onSaveClick}>Save</Save>}
				>
					Add defect
				</Nav>
				<ContentWrapper>
					<Overview>
						<List>
							<InputComponent
								onSave={onValueChange}
								dataKey="date"
								type="date"
								title="Date"
								initialValue={values.date}
							/>
							<InputComponent
								onSave={onValueChange}
								dataKey="type"
								title="Type"
								type="select"
								options={(get(userSelf, 'settings.defect_types') || []).map(t => ({ label: t, value: t }))}
								initialValue={[values.type]}
							/>
							<InputComponent
								placeholder="Description"
								onSave={onValueChange}
								dataKey="description"
								initialValue={values.description}
								title="Description"
								type="text"
							/>
							<InputComponent
								placeholder="MEL / Other doc. reference"
								onSave={onValueChange}
								dataKey="reference"
								initialValue={values.reference}
								title="MEL / Other doc. reference"
								type="text"
							/>
							<InputComponent
								placeholder="TLog reference"
								onSave={onValueChange}
								dataKey="log_reference"
								disabled={values.log_id}
								initialValue={values.log_reference}
								title="TLog reference"
							/>
							{(get(userSelf.settings, 'custom_aircraft_defect_fields') || []).map(field => (
								<InputComponent
									onSave={val => onValueChange({ custom_fields_data: { ...values.custom_fields_data, [field.id]: val[field.id] } })}
									key={field.id}
									dataKey={`${field.id}`}
									title={field.title}
									initialValue={get(values, `custom_fields_data.${field.id}`)}
								/>
							))}
							<InputComponent
								placeholder="Additional info"
								onSave={onValueChange}
								dataKey="additional_info"
								initialValue={values.additional_info}
								title="Addtitional info"
								type="text"
							/>
						</List>
						<List header="Due parameters">
							<InputComponent
								onSave={onValueChange}
								dataKey="due_date"
								type="date"
								title="Due date"
								initialValue={values.due_date}
							/>
							{TRACKING_PARAMETERS.map(param => (
								isEnabledParameter(aircraft.enabled_parameters || [], param.key) && <InputComponent
									onSave={onValueChange}
									key={param.key}
									min={0}
									dataKey={`due_${param.key}`}
									title={param.name}
									digits={param.precision}
									type={param.type === 'hours' ? 'hours' : 'number'}
									initialValue={values[`due_${param.key}`] || 0}
								/>))}
						</List>
						<List header="Done">
							<InputComponent
								onSave={onValueChange}
								dataKey="done_date"
								type="date"
								title="Done date"
								initialValue={values.done_date}
							/>
						</List>
					</Overview>
				</ContentWrapper>
			</DefectWrapper>
		</Popup>
	);
}

export default DefectPopup;

import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { FloatingBubble, Toast, PullToRefresh, SpinLoading, Dialog, ErrorBlock } from 'antd-mobile';
import { AddOutline } from 'antd-mobile-icons';
import styled from 'styled-components';
import { addAircraftBooking, deleteAircraftBooking, getActivities, getAircraftBooking, updateAircraftBooking } from '../api';
import { store } from '../utils/store';
import { matchesPermission } from '../utils/permission-helpers';

import BookingsList from '../components/BookingsList';
import { serialize } from '../utils/helpers';
import BookingPopup from '../components/BookingPopup';

import flightloggerLogo from '../assets/images/flightlogger_logo.png';

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

const LogbookWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;

const ListWrapper = styled.div`
	flex: 1;
	min-height: 0;
	overflow:  auto;
	width: 100%;
	background: #f0f2f5;
`;

const ToastContent = styled.div`
	word-break: normal;
	text-align: center;
	width: 100%;
`;

function Bookings(props) {
	const {
		aircraftId,
		flightloggerEnabled
	} = props;
	const globalState = useContext(store).state;
	const { userSelf } = globalState;
	const [loading, setLoading] = useState(false);
	const [saving, setSaving] = useState(false);
	const [showAddPopup, setShowPopup] = useState(false);
	const [records, setRecords] = useState([]);
	const [activeRecord, setActiveRecord] = useState(1);

	async function fetchRecords() {
		setLoading(true);

		try {
			const data = await getActivities(`?${serialize({
				start_date: moment().startOf('day').format(DATE_FORMAT),
				end_date: moment().add(30, 'day').endOf('day').format(DATE_FORMAT),
				aircraft_id: aircraftId,
				type: ['work_order', 'booking']
			})}`);

			setRecords(data.sort((a, b) => new Date(a.since) - new Date(b.since)));
		} catch (error) {
			Toast.show({
				icon: 'fail',
				content: <ToastContent>
					Unable to retrieve bookings. Something went wrong
				</ToastContent>
			});
		}

		setLoading(false);
	}

	useEffect(() => {
		if (aircraftId) {
			fetchRecords();
		}
	}, [aircraftId]);

	async function onSaveBooking(values) {
		setSaving(true);
		try {
			if (values.id) {
				await updateAircraftBooking(values.id, aircraftId, values);
			} else {
				await addAircraftBooking(aircraftId, values);
			}

			await fetchRecords();
			setShowPopup(false);
		} catch (e) {
			Toast.show({
				icon: 'fail',
				content: <ToastContent>
					Unable to save booking. Something went wrong.
				</ToastContent>
			});
		}
		setSaving(false);
	}

	async function onBookingClick(record) {
		try {
			const booking = await getAircraftBooking(record.id, aircraftId);
			setActiveRecord(booking);
			setShowPopup(true);
		} catch (error) {
			Toast.show({
				icon: 'fail',
				content: <ToastContent>
					Unable to edit the booking. Something went wrong.
				</ToastContent>
			});
		}
	}

	async function deleteBooking(id) {
		const result = await Dialog.confirm({
			cancelText: 'Cancel',
			confirmText: 'Yes',
			style: { textAlign: 'center' },
			content: 'Delete this booking?'
		});

		if (result) {
			setSaving(true);
			try {
				await deleteAircraftBooking(id, aircraftId);
				await fetchRecords();
				setShowPopup(false);
			} catch (error) {
				Toast.show({
					icon: 'fail',
					content: <ToastContent>
						Unable to delete the booking. Something went wrong.
					</ToastContent>
				});
			}
			setSaving(false);
		}
	}


	return (
		<LogbookWrapper>
			{matchesPermission(userSelf, 'add_aircraft_bookings') && !flightloggerEnabled && <FloatingBubble
				style={{
					'--initial-position-bottom': '74px',
					'--initial-position-right': 'calc(50% - 24px)',
					'--edge-distance': '24px'
				}}
				onClick={() => setShowPopup(true)}
			>
				{saving ? <SpinLoading /> : <AddOutline fontSize={32} />}
			</FloatingBubble>}
			{!flightloggerEnabled && <PullToRefresh
				onRefresh={async () => {
					await fetchRecords();
				}}
			>
				<ListWrapper>
					<BookingsList
						onClick={onBookingClick}
						saving={saving}
						records={records}
						loading={loading}
					/>
				</ListWrapper>
			</PullToRefresh>}
			{flightloggerEnabled && <ErrorBlock
				image={flightloggerLogo}
				style={{
					marginTop: 64,
					'--image-height': '40px'
				}}
				title="This aircraft has FlightLogger integration enabled"
				description="Please use FlightLogger to view all bookings"
			/>}
			<BookingPopup
				visible={showAddPopup}
				onSave={onSaveBooking}
				aircraftId={aircraftId}
				booking={activeRecord}
				saving={saving}
				onDelete={deleteBooking}
				onClose={() => {
					setShowPopup(false);
					setActiveRecord({});
				}}
			/>
		</LogbookWrapper>
	);
}

export default Bookings;

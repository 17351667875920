import React, { useContext, Fragment } from 'react';
import { List, SpinLoading, ErrorBlock, Ellipsis } from 'antd-mobile';
import moment from 'moment';
import styled from 'styled-components';
import { store } from '../utils/store';
import { matchesPermission } from '../utils/permission-helpers';
import { getDateTimeFormat } from '../utils/helpers';

const Records = styled(List)`
	width: 100%;
	padding: 12px 0px;
	.adm-list-item-content-extra {
		display: flex;
	}
`;

const SpinWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`;

function BookingsList(props) {
	const {
		records,
		onClick,
		loading
	} = props;
	const globalState = useContext(store).state;
	const { userSelf } = globalState;

	function getTypeTitle(type) {
		if (type === 'work_order') {
			return 'Work order';
		}

		if (type === 'booking') {
			return 'Booking';
		}

		if (type === 'flightlogger') {
			return 'Flightlogger booking';
		}

		return '';
	}

	function getBookingsList() {
		if (!records.length) {
			return <ErrorBlock
				style={{ paddingTop: 12 }}
				description={null}
				title={'No bookings found in next 30 days'}
				status='empty'
			/>;
		}

		return <Records>
			{records.map((record) => {
				return <List.Item
					key={record.id}
					title={<Fragment>
						<span>{`${record.since ? moment(record.since).format(getDateTimeFormat(userSelf)) : ''}`}</span>
						<span> - </span>
						<span>{`${record.till ? moment(record.till).format(getDateTimeFormat(userSelf)) : ''}`}</span>
					</Fragment>}
					description={<Fragment>
						<div>
							<Ellipsis direction='end' rows={2} content={record.description || '-'} />
						</div>
						{record.participants && record.participants.length && <div>
							<Ellipsis direction='end' rows={2} content={`Participants: ${record.participants.map(p => p.name).join(', ')}`} />
						</div>}
					</Fragment>}
					onClick={() => {
						if (record.type === 'booking' && matchesPermission(userSelf, 'edit_aircraft_bookings')) {
							onClick(record);
						}
					}}
					clickable={record.type === 'booking' && matchesPermission(userSelf, 'edit_aircraft_bookings')}
				>
					{getTypeTitle(record.type)}: {record.title || '-'}
				</List.Item>;
			})}
		</Records>;
	}

	return (
		loading ? <SpinWrapper><SpinLoading style={{ '--size': '48px' }} /></SpinWrapper> : <Fragment>
			{getBookingsList()}
		</Fragment>
	);
}

export default BookingsList;

import React, { useState, useContext, useEffect, Fragment } from 'react';
import { DotLoading, InfiniteScroll, NavBar, SearchBar } from 'antd-mobile';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { getAircrafts } from '../api';
import { store } from '../utils/store';
import AircraftsList from '../components/AircraftsList';

import logo from '../assets/images/logo-32x32.png';

const AircraftWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;
const ListWrapper = styled.div`
	flex: 1;
	min-height: 0;
	overflow:  auto;
	width: 100%;
	padding: 12px 0px;
	background: #f0f2f5;
`;

const Nav = styled(NavBar)`
	background: #fff;
	display: flex;
	border-bottom: solid 1px var(--adm-color-border);
	.adm-nav-bar-left,
	.adm-nav-bar-right {
		flex: 0;
	}
	.adm-nav-bar-title {
		padding: 0px 12px 0px 0px;
	}
`;

const Logo = styled.img`
	height: 32px;
`;

function useDebounce(value = '', delay) {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

		return () => {
			clearTimeout(timer);
		};
	}, [value, delay]);

	return debouncedValue;
}


function Aircrafts() {
	const globalState = useContext(store);
	const { userSelf } = globalState;
	const [loading, setLoading] = useState(false);
	const [err, setErr] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [aircrafts, setAircrafts] = useState([]);
	const [hasMore, setHasMore] = useState(false);
	const [page, setPage] = useState(1);

	async function fetchAircrafts(currentPage, silent) {
		if (!silent) {
			setLoading(true);
		}
		try {
			const data = await getAircrafts(`?page=${currentPage}${searchValue ? `&search=${searchValue}` : ''}`);
			let newList = data.items;

			if (currentPage > 1) {
				newList = [...aircrafts, ...newList];
			}

			setHasMore(newList.length < data.total_count);
			setAircrafts(newList);
		} catch (e) {
			setErr(true);
		}

		setLoading(false);
	}

	const debounced = useDebounce(searchValue, 500);

	useEffect(() => {
		fetchAircrafts(1);
		setPage(1);
	}, [debounced]);

	async function onLoadMore() {
		const newPage = page + 1;
		setPage(newPage);
		await fetchAircrafts(newPage, true);
	}

	const InfiniteScrollContent = ({ more }) => {
		return (
			<Fragment>
				{more ? (
					<Fragment>
						<span>Loading</span>
						<DotLoading />
					</Fragment>
				) : (
					<span></span>
				)}
			</Fragment>
		);
	};

	return (
		<AircraftWrapper>
			<Nav backArrow={false} back={<Logo src={logo} />} >
				<SearchBar value={searchValue} onChange={setSearchValue} placeholder='Search' />
			</Nav>
			<ListWrapper>
				<AircraftsList
					loading={loading}
					aircrafts={aircrafts}
				/>
				<InfiniteScroll
					loadMore={onLoadMore}
					hasMore={hasMore}
				>
					<InfiniteScrollContent />
				</InfiniteScroll>
			</ListWrapper>
		</AircraftWrapper>
	);
}

export default Aircrafts;

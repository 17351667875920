import isNumber from 'lodash/isNumber';
import get from 'lodash/get';
import { isArray, round } from 'lodash';
import moment from 'moment';

export const getAircraftStatusData = (status) => {
	switch (status) {
	case 'in_flight':
		return {
			text: 'IN FLIGHT',
			color: '#87d068'
		};
	case 'in_maintenance':
		return {
			text: 'IN MAINTENANCE',
			color: '#2db7f5'
		};
	case 'maintenance_near_due':
		return {
			text: 'NEAR DUE',
			color: '#fa8c16'
		};
	case 'maintenance_in_tolerance':
		return {
			text: 'IN TOLERANCE',
			color: '#f50'
		};
	case 'needs_maintenance':
		return {
			text: 'NEEDS MAINTENANCE',
			color: '#f5222d'
		};
	case 'out_of_order':
		return {
			text: 'OUT OF ORDER',
			color: null
		};
	case 'inactive':
		return {
			text: 'INACTIVE',
			color: null
		};
	default:
		return {};
	}
};

export const serialize = (obj) => {
	const str = [];
	Object.keys(obj).forEach((p) => {
		if (isArray(obj[p]) && !obj[p].length) {
			return;
		}

		if (!isNumber(obj[p]) && !obj[p]) {
			return;
		}

		if (obj.hasOwnProperty(p)) {
			str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
		}
	});
	return str.join('&');
};

export const getDateTimeFormat = userSelf => `${get(userSelf.settings, 'date_format')} ${get(userSelf.settings, 'time_format')}`;

export const getTrackingParameters = userSelf => get(userSelf, 'active_organization.tracking_parameters') || [];
export const isEnabledParameter = (enabledParameters = [], key) => enabledParameters.includes(key);

export const getMinutesFromHours = (hours) => {
	if (!hours) {
		return 0;
	}

	hours = Math.abs(hours);

	const pointValue = (hours - Math.trunc(hours));

	return Number(((Number(pointValue) * 60)).toFixed());
};

export const displayHoursWithMinutes = (hours) => {
	if (!isNumber(hours)) {
		return 0;
	}

	if (isNumber(hours) && !Number(hours)) {
		return '0:00';
	}

	return `${Math.trunc(hours)}:${`${getMinutesFromHours(hours)}`.padStart(2, '0')}`;
};

export const displayParameterValue = (value, field) => {
	if (!isNumber(value)) {
		return '-';
	}

	if (field.type === 'hours') {
		return displayHoursWithMinutes(value);
	}

	return round(value, field.precision);
};

export const getFieldStatusColor = (remaining, nearDue = 0) => {
	if (remaining <= 0) {
		return '#f5222d';
	}

	if (nearDue && remaining <= nearDue) {
		return '#faad14';
	}

	return null;
};

export const isUUID = (str) => {
	const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
	return uuidRegex.test(str);
};

export const convertHoursAndMinutes = (hours, minutes) => {
	if (!Number(minutes) && !Number(hours)) {
		return 0;
	}

	const isNegative = hours < 0;

	hours = Math.abs(Math.trunc(hours) || 0);
	minutes = Number((Number(minutes) / 60).toFixed(4)) || 0;

	const total = hours + minutes;

	return isNegative ? -(total) : total;
};

export const getCustomFieldValues = (values, customFields = []) => {
	const data = {};
	customFields.forEach((field) => {
		if (field.id in values) {
			data[field.id] = values[field.id];

			delete values[field.id];
		}
	});

	return data;
};

export const getStatusColor = (status) => {
	switch (status) {
	case 'not_due':
		return '#87d068';
	case 'near_due':
		return '#faad14';
	case 'past_due':
		return '#f5222d';
	case 'projection':
		return '#0800ff';
	case 'inactive':
		return '#777777';
	case 'open':
		return 'red';
	default:
		return '#87d068';
	}
};

export const getStatusData = (status) => {
	switch (status) {
	case 'active':
		return {
			text: 'ACTIVE',
			color: getStatusColor(status)
		};
	case 'not_due':
		return {
			text: 'NOT DUE',
			color: getStatusColor(status)
		};
	case 'near_due':
		return {
			text: 'NEAR DUE',
			color: getStatusColor(status)
		};
	case 'past_due':
		return {
			text: 'PAST DUE',
			color: getStatusColor(status)
		};
	case 'inactive':
		return {
			text: 'INACTIVE',
			color: getStatusColor(status)
		};
	case 'open':
		return {
			text: 'OPEN',
			color: getStatusColor(status)
		};
	case 'closed':
		return {
			text: 'CLOSED',
			color: 'default'
		};
	default:
		return {
			text: 'NOT DUE',
			color: getStatusColor(status)
		};
	}
};

import React, { useContext, useEffect, useState, Fragment } from 'react';
import { CheckList, DotLoading, NavBar, Popup } from 'antd-mobile';
import { get } from 'lodash';
import { CloseOutline, CheckCircleFill, CheckCircleOutline } from 'antd-mobile-icons';
import styled from 'styled-components';
import { store } from '../utils/store';

const MaterialsWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;

const ContentWrapper = styled.div`
	flex: 1;
	min-height: 0;
	overflow: auto;
	width: 100%;
	background: #f0f2f5;
`;

const Nav = styled(NavBar)`
	background: #fff;
	display: flex;
	border-bottom: solid 1px var(--adm-color-border);
`;


function ChecklistPopup(props) {
	const {
		visible,
		onClose,
		onChange,
		disabled,
		checklistData,
		checklist,
		saving
	} = props;
	const globalState = useContext(store);
	const [checked, setChecked] = useState(get(checklistData[checklist.id], 'checked') || []);
	const { userSelf } = globalState;

	function handleChecklistChange(values) {
		setChecked(values);
		const existingData = checklistData[checklist.id] || {};
		const newData = {
			...existingData,
			checked: values
		};

		onChange({
			checklist_data: {
				...checklistData,
				[checklist.id]: newData
			}
		});
	}

	useEffect(() => {
		setChecked(get(checklistData[checklist.id], 'checked') || []);
	}, [checklist]);

	return (
		<Fragment>
			<Popup
				position='right'
				visible={visible}
				destroyOnClose={true}
				bodyStyle={{ width: '100%' }}
				onClose={onClose}
			>
				<MaterialsWrapper>
					<Nav
						backArrow={<CloseOutline />}
						onBack={onClose}
						back={''}
						right={saving && <DotLoading />}
					>
						{checklist.title}
					</Nav>
					<ContentWrapper>
						<CheckList
							multiple
							extra={active =>
								(active ?
									<CheckCircleFill fontSize={24} color="var(--adm-color-success)" /> :
									<CheckCircleOutline fontSize={24} color="var(--adm-color-light)" />
								)
							}
							onChange={handleChecklistChange}
							value={checked}
						>
							{(get(checklist, 'checklist') || []).map((c, i) => <CheckList.Item disabled={disabled} key={i} value={c}>{c}</CheckList.Item>)}
						</CheckList>
					</ContentWrapper>
				</MaterialsWrapper>
			</Popup>
		</Fragment>
	);
}

export default ChecklistPopup;

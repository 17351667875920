import React, { Fragment, useContext, useState } from 'react';
import moment from 'moment';
import { Button, Ellipsis, List, Skeleton, Toast } from 'antd-mobile';
import styled from 'styled-components';
import { get, groupBy } from 'lodash';
import { store } from '../utils/store';
import {
	displayParameterValue,
	getAircraftNextMaintenanceColor,
	getRemaining,
	getRemainingDays,
	getTrackingParameters,
	isEnabledParameter
} from '../utils/helpers';
import { matchesPermission } from '../utils/permission-helpers';
import AircraftPopup from './AircraftPopup';
import { updateAircraft } from '../api';

const InfoWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;

const InfoHeader = styled.div`
	padding: 12px;
`;

const UpdateStatusesButton = styled(Button)`
`;

const AircraftInfoContent = styled.div`
	margin-top: 12px;
	padding-bottom: 12px;
	.adm-list-item-title {
		display: flex;
		align-items: center;
	}

	h2 {
		margin: 0px 8px 0px 0px;
		display: inline-block;
		color: var(--adm-color-text);
	}

	.adm-ellipsis {
		a {
			margin-left: 8px;
		}
	}
`;


function AircraftInfo(props) {
	const {
		aircraft,
		onAircraftChange,
		loading
	} = props;

	const globalState = useContext(store).state;
	const { userSelf } = globalState;
	const [showPopup, setShowPopup] = useState(false);

	const TRACKING_PARAMETERS = getTrackingParameters(userSelf);
	const groupedParametersByCategory = groupBy(TRACKING_PARAMETERS, 'category');

	const [saving, setSaving] = useState(false);

	async function updateAircraftParameters(payload) {
		setSaving(true);
		try {
			await updateAircraft(aircraft.id, payload);
			await onAircraftChange();
			setShowPopup(false);
		} catch (e) {
			Toast.show({
				icon: 'fail',
				content: <ToastContent>
					Something went wrong, cannot update aircraft.
				</ToastContent>
			});
		}
		setSaving(false);
	}

	return (<InfoWrapper>
		<AircraftInfoContent>
			{loading ?
				<Fragment>
					<Skeleton.Title animated/>
					<Skeleton.Paragraph lineCount={1} animated/>
				</Fragment> :
				<Fragment>
					{matchesPermission(userSelf, 'edit_aircrafts') && <InfoHeader>
						<UpdateStatusesButton
							block
							onClick={() => setShowPopup(true)}
							color='primary'
						>
							Update status
						</UpdateStatusesButton>
					</InfoHeader>}
					<List header="Current status">
						{Object.keys(groupedParametersByCategory)
							.filter(key => groupedParametersByCategory[key].some(param => isEnabledParameter(aircraft.enabled_parameters, param.key)))
							.map((key) => {
								return <Fragment key={key}>
									{groupedParametersByCategory[key].map((param) => {
										if (isEnabledParameter(aircraft.enabled_parameters, param.key)) {
											return <List.Item
												key={param.key}
												title={param.name}
												clickable={false}
											>
												{displayParameterValue(aircraft[param.key], param)}
											</List.Item>;
										}

										return null;
									})}
								</Fragment>;
							})}
					</List>
					<List header="Next maintenance">
						<List.Item
							title="Date"
							extra={`${getRemainingDays(get(aircraft, 'stats.next_due_date'))} days left`}
							clickable={false}
							description={<Ellipsis direction='end' rows={1} content={get(aircraft, 'stats.date_name')} />}
						>
							<span style={{ color: getAircraftNextMaintenanceColor(aircraft, 'date') }}>
								{get(aircraft, 'stats.next_due_date') ? moment(get(aircraft, 'stats.next_due_date')).format(get(userSelf, 'settings.date_format')) : '-' }
							</span>
						</List.Item>
						{Object.keys(groupedParametersByCategory)
							.filter(key => groupedParametersByCategory[key].some(param => isEnabledParameter(aircraft.enabled_parameters, param.key)))
							.map((key) => {
								return <Fragment key={key}>
									{groupedParametersByCategory[key].map((param) => {
										if (isEnabledParameter(aircraft.enabled_parameters, param.key)) {
											return <List.Item
												key={param.key}
												title={param.name}
												extra={`${getRemaining(aircraft[param.key], get(aircraft, `stats.next_due_${param.key}`), param)} left`}
												clickable={false}
												description={<Ellipsis direction='end' rows={1} content={get(aircraft, `stats.next_due_${param.key}_name`)} />}
											>
												<span style={{ color: getAircraftNextMaintenanceColor(aircraft, param.key) }}>
													{displayParameterValue(get(aircraft, `stats.next_due_${param.key}`), param)}
												</span>
											</List.Item>;
										}

										return null;
									})}
								</Fragment>;
							})}
					</List>
				</Fragment>}
			<AircraftPopup
				visible={showPopup}
				onSave={updateAircraftParameters}
				aircraft={aircraft}
				saving={saving}
				onClose={() => setShowPopup(false)}
			/>
		</AircraftInfoContent>
	</InfoWrapper>);
}

export default AircraftInfo;

import React, { useContext, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { List, Ellipsis, SpinLoading, Tag, ErrorBlock, Image } from 'antd-mobile';
import styled from 'styled-components';
import { store } from '../utils/store';
import { getAircraftStatusData } from '../utils/helpers';

const Aircrafts = styled(List)`
	width: 100%;
`;

const Status = styled(Tag)`
`;

const SpinWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`;

function AircraftsList(props) {
	const {
		aircrafts,
		loading
	} = props;
	const globalState = useContext(store);
	const history = useHistory();
	const { userSelf } = globalState;

	function getList() {
		if (!aircrafts.length) {
			return <ErrorBlock
				style={{ paddingTop: 12 }}
				title="No aircraft found"
				description="Try new search?"
				status='empty'
			/>;
		}

		return <Aircrafts>
			{aircrafts.map(ac => (
				<List.Item
					key={ac.id}
					extra={<Fragment>
						<Status color={getAircraftStatusData(ac.status).color}>
							{getAircraftStatusData(ac.status).text}
						</Status>
					</Fragment>}
					prefix={
						<Image
							src={`${process.env.API_URL}${ac.img}`}
							fit='cover'
							width={56}
							height={56}
						/>
					}
					description={<Ellipsis direction='end' rows={3} content={ac.model || '-'} />}
					onClick={() => history.push(`/aircraft/${ac.id}`)}
					clickable
				>
					{ac.registration || '-'}
				</List.Item>
			))}
		</Aircrafts>;
	}

	return (
		loading ? <SpinWrapper><SpinLoading style={{ '--size': '48px' }} /></SpinWrapper> : getList()
	);
}

export default AircraftsList;

import React, { useState, useContext, useEffect, Fragment } from 'react';
import { NavBar, Tabs, List, Skeleton, ErrorBlock, Badge, Button, Dialog, Toast, Tag, Ellipsis } from 'antd-mobile';
import styled from 'styled-components';
import get from 'lodash/get';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { deleteAircraftDefect, getAircraft, getAircraftDefect, updateAircraftDefect } from '../api';
import { store } from '../utils/store';
import InputComponent from '../components/Input';
import Files from './Files';
import Notes from './Notes';
import { matchesPermission } from '../utils/permission-helpers';
import { getTrackingParameters, isEnabledParameter, getCustomFieldValues, getStatusData, getStatusColor } from '../utils/helpers';


const DefectWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;
const ContentWrapper = styled.div`
	flex: 1;
	min-height: 0;
	overflow:  auto;
	width: 100%;
	background: #f0f2f5;
`;

const Overview = styled.div`
	padding: 12px 0px;
`;

const DefectInfo = styled.div`
	padding: 0px 12px;
	background: #fff;

	h2 {
		margin: 0px 8px 0px 0px;
		display: inline-block;
		color: var(--adm-color-text);
	}

	.adm-ellipsis {
		a {
			margin-left: 8px;
		}
	}
`;

const NotFound = styled(ErrorBlock)`
	height: 100%;
	padding: 12px;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
`;

const Nav = styled(NavBar)`
	background: #fff;
	display: flex;
	border-bottom: solid 1px var(--adm-color-border);
`;

const Middot = styled.span`
	padding: 0px 4px;
`;

const TabBar = styled(Tabs)`
	display: flex;
	flex-direction: column;
	height: 100%;
	.adm-tabs-header {
		background: #fff;
	}

	.adm-tabs-content {
		flex: 1;
		overflow: auto;
		padding: 0;
	}
`;

const ToastContent = styled.div`
	word-break: normal;
	text-align: center;
	width: 100%;
`;

const ButtonWrapper = styled.div`
	margin-top: 12px;
	padding: 12px;
`;

const Description = styled(List.Item)`
	font-size: var(--adm-font-size-9);
`;

function Defect() {
	const params = useParams();
	const history = useHistory();
	const { id, aircraftId } = params;
	const globalState = useContext(store).state;
	const { userSelf } = globalState;
	const [loading, setLoading] = useState(false);
	const [saving, setSaving] = useState(false);
	const [err, setErr] = useState(false);
	const [record, setRecord] = useState({});
	const [aircraft, setAircraft] = useState({});

	const TRACKING_PARAMETERS = getTrackingParameters(userSelf);

	async function fetchRecord(silent) {
		if (!silent) {
			setLoading(true);
		}

		try {
			const data = await getAircraftDefect(aircraftId, id);
			setRecord(data);
		} catch (e) {
			setErr(true);
		}

		setLoading(false);
	}


	async function fetchAircraft() {
		setLoading(true);
		try {
			const data = await getAircraft(aircraftId);
			setAircraft(data);
		} catch (e) {
			setErr(true);
		}
		setLoading(false);
	}

	useEffect(() => {
		fetchRecord();
		fetchAircraft();
	}, []);

	async function updateRecord(data) {
		let payload = data;
		payload.id = record.id;

		if ((get(userSelf.settings, 'custom_aircraft_defect_fields') || []).some(f => f.id in payload)) {
			const customData = {
				...(record.custom_fields_data || {}),
				...getCustomFieldValues(payload, get(userSelf.settings, 'custom_aircraft_defect_fields') || [])
			};

			payload =
				{ ...{ custom_fields_data: customData }, ...payload };
		}


		const respData = await updateAircraftDefect(aircraftId, payload);

		setRecord(respData);
	}

	async function onDelete() {
		const result = await Dialog.confirm({
			cancelText: 'Cancel',
			confirmText: 'Yes',
			style: { textAlign: 'center' },
			content: 'Delete this defect?'
		});

		if (result) {
			setSaving(true);
			await deleteAircraftDefect(aircraftId, record.id).catch(() => {
				Toast.show({
					icon: 'fail',
					content: <ToastContent>
						Something went wrong, cannot delete defect.
					</ToastContent>
				});
			});

			setSaving(false);
			history.push(`/aircraft/${aircraftId}`);
		}
	}


	return (err ? <NotFound
		title="Logbook"
		description="This defect does not exist, or you dont have rights to view it"
		status='empty'
	/> : <DefectWrapper>
		<Nav onBack={() => history.goBack()} back={''}>
			Defect
		</Nav>
		<DefectInfo>
			{loading ? <Skeleton.Paragraph lineCount={3} animated/> :
				<Description
					key={record.id}
					description={record.reference}
					title={<Fragment>
						<span>{`${record.date ? moment(record.date).format(get(userSelf.settings, 'date_format')) : ''}`}</span>
						{record.user_name && record.date && <Middot>&middot;</Middot>}
						<span>{record.user_name}</span>
						<Middot>&middot;</Middot>
						<Tag color={getStatusData(record.status).color}>
							{getStatusData(record.status).text}
						</Tag>
					</Fragment>}
					clickable={false}
				>
					<Ellipsis
						direction='end'
						rows={4} content={record.description || '-'}
						expandText="Show"
						collapseText="Hide"
					/>
				</Description>
			}
		</DefectInfo>
		<ContentWrapper>
			<TabBar>
				<Tabs.Tab
					title={'Details'}
					key='details'
				>
					<Overview>
						<List>
							<InputComponent
								onSave={updateRecord}
								dataKey="date"
								type="date"
								disabled={!matchesPermission(userSelf, 'edit_aircraft_defects')}
								title="Date"
								initialValue={record.date}
							/>
							<InputComponent
								onSave={updateRecord}
								dataKey="type"
								title="Type"
								disabled={!matchesPermission(userSelf, 'edit_aircraft_defects')}
								type="select"
								options={(get(userSelf, 'settings.defect_types') || []).map(t => ({ label: t, value: t }))}
								initialValue={[record.type]}
							/>
							<InputComponent
								placeholder="Description"
								onSave={updateRecord}
								dataKey="description"
								disabled={!matchesPermission(userSelf, 'edit_aircraft_defects')}
								initialValue={record.description}
								title="Description"
								type="text"
							/>
							<InputComponent
								placeholder="MEL / Other doc. reference"
								onSave={updateRecord}
								dataKey="reference"
								disabled={!matchesPermission(userSelf, 'edit_aircraft_defects')}
								initialValue={record.reference}
								title="MEL / Other doc. reference"
								type="text"
							/>
							<InputComponent
								placeholder="TLog reference"
								onSave={updateRecord}
								dataKey="log_reference"
								disabled={record.log_id || !matchesPermission(userSelf, 'edit_aircraft_defects')}
								initialValue={record.log_reference}
								title="TLog reference"
							/>
							{record.related_work_orders?.length ?
								<List.Item title="Work order" clickable={false}>
									{(record.related_work_orders.map((r, i) => <span key={i}>
										{r.work_order}
										{record.related_work_orders.length !== i + 1 ? ', ' : ''}
									</span>))}
								</List.Item> :
								<InputComponent
									placeholder="Work order"
									onSave={updateRecord}
									dataKey="work_order_reference"
									initialValue={record.work_order_reference || !matchesPermission(userSelf, 'edit_aircraft_defects')}
									title="Work order"
								/>}
							{(get(userSelf.settings, 'custom_aircraft_defect_fields') || []).map(field => (
								<InputComponent
									onSave={updateRecord}
									key={field.id}
									dataKey={`${field.id}`}
									disabled={!matchesPermission(userSelf, 'edit_aircraft_defects')}
									title={field.title}
									initialValue={get(record, `custom_fields_data.${field.id}`)}
								/>
							))}
							<InputComponent
								placeholder="Additional info"
								onSave={updateRecord}
								dataKey="additional_info"
								disabled={!matchesPermission(userSelf, 'edit_aircraft_defects')}
								initialValue={record.additional_info}
								title="Addtitional info"
								type="text"
							/>
						</List>
						<List header="Due parameters">
							<InputComponent
								onSave={updateRecord}
								dataKey="due_date"
								color={(record.due_fields || []).includes('date') && getStatusColor(record.status)}
								type="date"
								title="Due date"
								disabled={!matchesPermission(userSelf, 'edit_aircraft_defects')}
								initialValue={record.due_date}
							/>
							{TRACKING_PARAMETERS.map(param => (
								isEnabledParameter(aircraft.enabled_parameters || [], param.key) && <InputComponent
									onSave={updateRecord}
									key={param.key}
									color={(record.due_fields || []).includes(param.key) && getStatusColor(record.status)}
									min={0}
									disabled={!matchesPermission(userSelf, 'edit_aircraft_defects')}
									dataKey={`due_${param.key}`}
									title={param.name}
									digits={param.precision}
									type={param.type === 'hours' ? 'hours' : 'number'}
									initialValue={record[`due_${param.key}`] || 0}
								/>))}
						</List>
						<List header="Done">
							<InputComponent
								onSave={updateRecord}
								dataKey="done_date"
								type="date"
								disabled={!matchesPermission(userSelf, 'edit_aircraft_defects')}
								title="Done date"
								initialValue={record.done_date}
							/>
						</List>
						{matchesPermission(userSelf, 'delete_aircraft_defects') && <ButtonWrapper>
							<Button block saving={saving} onClick={onDelete} color="danger" fill="outline">Delete</Button>
						</ButtonWrapper>}
					</Overview>
				</Tabs.Tab>
				<Tabs.Tab
					title={<Badge
						content={record.files_count || null}
						style={{ '--color': 'var(--adm-color-primary)', '--right': '-10px', '--top': '4px' }}>
							Files
					</Badge>}
					key='files'
				>
					<Files
						onChange={() => fetchRecord(true)}
						objectType="aircraft_defect"
						disabled={!matchesPermission(userSelf, 'manage_files_notes_aircraft_defects')}
						objectId={record.id}
					/>
				</Tabs.Tab>
				<Tabs.Tab
					title={<Badge
						content={record.notes_count || null}
						style={{ '--color': 'var(--adm-color-primary)', '--right': '-10px', '--top': '4px' }}>
							Notes
					</Badge>}
					key='notes'
				>
					<Notes
						onChange={() => fetchRecord(true)}
						objectType="aircraft_defect"
						disabled={!matchesPermission(userSelf, 'manage_files_notes_aircraft_defects')}
						objectId={record.id}
					/>
				</Tabs.Tab>
			</TabBar>
		</ContentWrapper>
	</DefectWrapper>);
}

export default Defect;

import React, { useContext } from 'react';
import { TabBar } from 'antd-mobile';
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import {
	TravelOutline,
	UserOutline
} from 'antd-mobile-icons';
import styled from 'styled-components';
import { store } from '../utils/store';

const BottomBar = styled.div`
	flex: 0;
	width: 100%;
	background: #001529;
	color: #fff;
 	border-top: solid 1px var(--adm-color-border);
	.adm-tab-bar-item-active {
		color: #fff;
		background: var(--adm-color-primary);
	}
	.adm-tab-bar-item {
		color: #fff;
	}
`;

const HIDE_MENU = ['/login'];

const pathNamesMap = {
	'/': 'aircrafts',
	'/aircrafts': 'aircrafts',
	'/aircraft/*': 'aircrafts',
	'/profile': 'profile'
};

const routeMap = {
	aircrafts: '/',
	profile: '/profile'
};

function Navigation() {
	const globalState = useContext(store);
	const { userSelf } = globalState;

	const history = useHistory();
	const location = useLocation();
	const { pathname } = location;

	function getMatchingPathKeys() {
		const keys = [];
		Object.keys(pathNamesMap).forEach((key) => {
			const match = matchPath(pathname, {
				path: key,
				exact: true,
				strict: false
			});

			if (match) {
				keys.push(pathNamesMap[key]);
			}
		});

		return keys;
	}

	const tabs = [
		{
			path: '/',
			key: 'aircrafts',
			title: 'Aircraft',
			icon: <TravelOutline />
		},
		{
			path: '/profile',
			key: 'profile',
			title: 'Profile',
			icon: <UserOutline />
		}
	];

	const setRouteActive = (value) => {
		history.push(routeMap[value]);
	};

	return (!HIDE_MENU.includes(pathname) && <BottomBar>
		<TabBar activeKey={getMatchingPathKeys()[0]} onChange={value => setRouteActive(value)}>
			{tabs.map(item => (
				<TabBar.Item key={item.key} icon={item.icon} title={item.title} />
			))}
		</TabBar>
	</BottomBar>);
}

export default Navigation;

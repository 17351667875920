import React, { useState, useContext, useEffect, Fragment } from 'react';
import moment from 'moment';
import get from 'lodash/get';
import { FloatingBubble, InfiniteScroll, DotLoading, Toast, PullToRefresh, SpinLoading, ErrorBlock } from 'antd-mobile';
import { useHistory } from 'react-router-dom';
import { AddOutline } from 'antd-mobile-icons';
import styled from 'styled-components';
import { addFlightLog, getFlightLog } from '../api';
import FlightLogList from '../components/LogbookList';
import { store } from '../utils/store';
import { matchesPermission } from '../utils/permission-helpers';

import flightloggerLogo from '../assets/images/flightlogger_logo.png';

const LogbookWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;

const ListWrapper = styled.div`
	flex: 1;
	min-height: 0;
	overflow:  auto;
	width: 100%;
	background: #f0f2f5;
`;

const ToastContent = styled.div`
	word-break: normal;
	text-align: center;
	width: 100%;
`;

function Logbook(props) {
	const {
		aircraftId,
		flightloggerEnabled
	} = props;
	const globalState = useContext(store).state;
	const { userSelf } = globalState;
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [saving, setSaving] = useState(false);
	const [err, setErr] = useState(false);
	const [records, setRecords] = useState([]);
	const [hasMore, setHasMore] = useState(false);
	const [page, setPage] = useState(1);

	async function fetchRecords(currentPage, silent, clear) {
		if (!silent) {
			setLoading(true);
		}

		try {
			const data = await getFlightLog(aircraftId, `?page=${currentPage}`);
			const newList = [...(clear ? [] : records), ...data.items];
			setHasMore(newList.length < data.total_count);
			setRecords(newList);
		} catch (e) {
			setErr(true);
		}

		setLoading(false);
	}

	useEffect(() => {
		if (aircraftId) {
			fetchRecords(page);
		}
	}, [aircraftId]);

	async function onLoadMore() {
		const newPage = page + 1;
		setPage(newPage);
		await fetchRecords(newPage, true);
	}

	const InfiniteScrollContent = ({ more }) => {
		return (
			<Fragment>
				{more ? (
					<Fragment>
						<span>Loading</span>
						<DotLoading />
					</Fragment>
				) : (
					<span></span>
				)}
			</Fragment>
		);
	};

	function generateFlightNumber() {
		if (records[0]) {
			const lastFlight = records[0].flight;
			const numbers = lastFlight.match(/\d+/);

			if (numbers) {
				return lastFlight.replace(/\d+/g, (Number(numbers[0]) + 1));
			}
			return lastFlight.concat('1');
		}
		return '1';
	}

	function generateTlog() {
		if (records[0]) {
			const lastTlog = records[0].tlog;
			const numbers = lastTlog.match(/\d+/);

			if (numbers) {
				return lastTlog.replace(/\d+/g, (Number(numbers[0]) + 1));
			}
			return lastTlog.concat('1');
		}
		return '1';
	}

	async function addNew() {
		setSaving(true);
		try {
			const newItem = await addFlightLog(aircraftId, {
				date_toff: moment(),
				time_toff: moment().format('HH:mm:ss'),
				off_block: moment(),
				from: get(records, '[0].to') || null,
				tlog: generateTlog(),
				flight: generateFlightNumber()
			});

			history.push(`/aircraft/${aircraftId}/logbook/${newItem.id}`);
		} catch (e) {
			Toast.show({
				icon: 'fail',
				content: <ToastContent>
					Something went wrong, cannot create new logbook entry.
				</ToastContent>
			});
		}
		setSaving(false);
	}

	return (
		<LogbookWrapper>
			{matchesPermission(userSelf, 'add_utilization') && !flightloggerEnabled && <FloatingBubble
				style={{
					'--initial-position-bottom': '74px',
					'--initial-position-right': 'calc(50% - 24px)',
					'--edge-distance': '24px'
				}}
				onClick={addNew}
			>
				{saving ? <SpinLoading /> : <AddOutline fontSize={32} />}
			</FloatingBubble>}
			{!flightloggerEnabled && <PullToRefresh
				onRefresh={async () => {
					await fetchRecords(1, true, true);
				}}
			>
				<ListWrapper>
					<FlightLogList
						saving={saving}
						records={records}
						loading={loading}
					/>
					<InfiniteScroll
						loadMore={onLoadMore}
						hasMore={hasMore}
					>
						<InfiniteScrollContent />
					</InfiniteScroll>
				</ListWrapper>
			</PullToRefresh>}
			{flightloggerEnabled && <ErrorBlock
				image={flightloggerLogo}
				style={{
					marginTop: 64,
					'--image-height': '40px'
				}}
				title="This aircraft has FlightLogger integration enabled"
				description="Please use FlightLogger to log all flight activities"
			/>}
		</LogbookWrapper>
	);
}

export default Logbook;

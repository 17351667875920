/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { NavBar, List, Popup, Button } from 'antd-mobile';
import { CloseOutline } from 'antd-mobile-icons';
import moment from 'moment';
import get from 'lodash/get';
import omit from 'lodash/omit';
import styled from 'styled-components';
import { store } from '../utils/store';
import InputComponent from './Input';
import { featureEnabled, matchesPermission } from '../utils/permission-helpers';
import { getList } from '../api';

const BookingWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;
const ContentWrapper = styled.div`
	flex: 1;
	min-height: 0;
	overflow: auto;
	width: 100%;
	background: #f0f2f5;
`;

const Overview = styled.div`
	padding: 12px 0px;
`;

const ButtonWrapper = styled.div`
	padding: 12px;
`;

const Nav = styled(NavBar)`
	background: #fff;
	display: flex;
	border-bottom: solid 1px var(--adm-color-border);
`;

const Save = styled(Button)`
	font-size: 17px;
`;

function BookingPopup(props) {
	const {
		booking,
		visible,
		saving,
		onClose,
		onDelete,
		aircraftId,
		onSave
	} = props;
	const globalState = useContext(store).state;
	const { userSelf } = globalState;
	const [users, setUsers] = useState([]);


	const [values, setValues] = useState({});

	async function fetchUsers() {
		if (featureEnabled(userSelf, 'staff_enabled')) {
			const usersData = await getList('users').catch();

			if (usersData) {
				setUsers(usersData);
			}
		}
	}

	function onSaveClick() {
		const saveValues = {
			...omit(values, ['participants', 'created_at', 'deleted', 'user_id']),
			participant_ids: values.participants.map(p => p.value),
			aircraft_id: aircraftId
		};

		onSave(saveValues);
	}

	useEffect(() => {
		if (visible) {
			fetchUsers();
		}

		if (booking.id) {
			setValues({
				...booking,
				participants: booking.participants ? booking.participants.map(p => ({ label: p.name, value: p.id })) : []
			});
		} else {
			setValues({
				start: moment(),
				end: moment().add(30, 'minutes'),
				participants: [{ label: userSelf.name, value: userSelf.id }]
			});
		}
	}, [visible]);

	async function onValueChange(data) {
		const newValues = { ...values, ...data };
		const start = newValues.start ? new Date(newValues.start) : null;
		const end = newValues.end ? new Date(newValues.end) : null;

		if (start && end && start > end) {
			newValues.end = start;
		}

		setValues(newValues);
	}

	return (
		<Popup
			position='right'
			visible={visible}
			bodyStyle={{ width: '100%' }}
			onClose={onClose}
		>
			<BookingWrapper>
				<Nav
					backArrow={<CloseOutline />}
					onBack={onClose}
					back={''}
					right={<Save fill='none' loading={saving} onClick={onSaveClick}>Save</Save>}
				>
					{booking.id ? 'Edit booking' : 'Add booking'}
				</Nav>
				<ContentWrapper>
					<Overview>
						<List>
							<InputComponent
								onSave={onValueChange}
								dataKey="type"
								title="Type"
								type="select"
								options={(get(userSelf, 'settings.aircraft_booking_types') || []).map(t => ({ label: t, value: t }))}
								initialValue={[values.type]}
							/>
							<InputComponent
								onSave={onValueChange}
								dataKey="start"
								title="Starts"
								type="datetime"
								initialValue={values.start ? new Date(values.start) : null}
							/>
							<InputComponent
								onSave={onValueChange}
								dataKey="end"
								title="Ends"
								type="datetime"
								initialValue={values.end ? new Date(values.end) : null}
							/>
							<InputComponent
								placeholder="Description"
								onSave={onValueChange}
								dataKey="description"
								initialValue={values.description}
								title="Description"
								type="text"
							/>
							<InputComponent
								placeholder="Participants"
								onSave={onValueChange}
								dataKey="participants"
								initialValue={values.participants || []}
								options={users.map(u => ({
									value: u.id,
									label: u.name
								}))}
								title="Participants"
								type="select-multiple"
							/>
						</List>
					</Overview>
					{matchesPermission(userSelf, 'delete_aircraft_bookings') && booking.id && <ButtonWrapper>
						<Button block saving={saving} onClick={() => onDelete(booking.id)} color="danger" fill="outline">Delete</Button>
					</ButtonWrapper>}
				</ContentWrapper>
			</BookingWrapper>
		</Popup>
	);
}

export default BookingPopup;

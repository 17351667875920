import React, { useState, useContext, useEffect, Fragment } from 'react';
import { DotLoading, FloatingBubble, InfiniteScroll, PullToRefresh, Toast } from 'antd-mobile';
import { AddOutline } from 'antd-mobile-icons';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { addAircraftDefect, getAircraftDefects } from '../api';
import { store } from '../utils/store';
import DefectsList from '../components/DefectsList';
import { matchesPermission } from '../utils/permission-helpers';
import DefectPopup from '../components/DefectPopup';

const DefectsWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;

const ListWrapper = styled.div`
	flex: 1;
	min-height: 0;
	overflow:  auto;
	width: 100%;
	background: #f0f2f5;
`;

function Defects(props) {
	const {
		aircraft,
		logId,
		logReference,
		stats
	} = props;

	const aircraftId = aircraft.id;

	const globalState = useContext(store).state;
	const { userSelf } = globalState;
	const history = useHistory();
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [showAddPopup, setShowAddPopup] = useState(false);
	const [err, setErr] = useState(false);
	const [defects, setDefects] = useState([]);
	const [hasMore, setHasMore] = useState(false);
	const [page, setPage] = useState(1);

	async function fetchDefects(currentPage, silent, clear) {
		if (!silent) {
			setLoading(true);
		}

		const query = `?page=${currentPage}${logId ? `&log_id=${logId}` : ''}`;

		setPage(currentPage);

		try {
			const data = await getAircraftDefects(aircraftId, query);
			const newList = [...(clear ? [] : defects), ...data.items];
			setHasMore(newList.length < data.total_count);
			setDefects(newList);
		} catch (e) {
			setErr(true);
		}

		setLoading(false);
	}

	const InfiniteScrollContent = ({ more }) => {
		return (
			<Fragment>
				{more ? (
					<Fragment>
						<span>Loading</span>
						<DotLoading />
					</Fragment>
				) : (
					<span></span>
				)}
			</Fragment>
		);
	};

	async function onLoadMore() {
		const newPage = page + 1;
		await fetchDefects(newPage, true);
	}

	useEffect(() => {
		if (aircraftId) {
			fetchDefects(page);
		}
	}, [aircraftId]);

	async function addNew(values) {
		setSaving(true);
		try {
			const newItem = await addAircraftDefect(aircraftId, values);

			history.push(`/aircraft/${aircraftId}/defect/${newItem.id}`);
		} catch (e) {
			Toast.show({
				icon: 'fail',
				content: <ToastContent>
					Something went wrong, cannot create new defect.
				</ToastContent>
			});
		}
		setSaving(false);
	}

	return (
		<DefectsWrapper>
			{matchesPermission(userSelf, 'add_aircraft_defects') && <FloatingBubble
				style={{
					'--initial-position-bottom': '74px',
					'--initial-position-right': 'calc(50% - 24px)',
					'--edge-distance': '24px'
				}}
				onClick={() => setShowAddPopup(true)}
			>
				<AddOutline fontSize={32} />
			</FloatingBubble>}
			<PullToRefresh
				onRefresh={async () => {
					await fetchDefects(1, true, true);
				}}
			>
				<ListWrapper>
					<DefectsList
						stats={stats}
						loading={loading}
						defects={defects}
					/>
					<InfiniteScroll
						loadMore={onLoadMore}
						hasMore={hasMore}
					>
						<InfiniteScrollContent />
					</InfiniteScroll>
				</ListWrapper>
			</PullToRefresh>
			<DefectPopup
				visible={showAddPopup}
				onSave={addNew}
				defect={{
					log_id: logId,
					log_reference: logReference
				}}
				aircraft={aircraft}
				saving={saving}
				onClose={() => setShowAddPopup(false)}
			/>
		</DefectsWrapper>
	);
}

export default Defects;

/* eslint-disable no-nested-ternary */
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { NavBar, List, Popup, Button } from 'antd-mobile';
import { CloseOutline } from 'antd-mobile-icons';
import { Parser } from 'expr-eval';
import groupBy from 'lodash/groupBy';
import round from 'lodash/round';
import styled from 'styled-components';
import { store } from '../utils/store';
import InputComponent from './Input';
import { displayParameterValue, getTrackingParameters, isEnabledParameter } from '../utils/helpers';

const ParamsWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;
const ContentWrapper = styled.div`
	flex: 1;
	min-height: 0;
	overflow: auto;
	width: 100%;
	background: #f0f2f5;
`;

const Overview = styled.div`
	padding: 12px 0px;
`;

const Nav = styled(NavBar)`
	background: #fff;
	display: flex;
	border-bottom: solid 1px var(--adm-color-border);
`;

const Save = styled(Button)`
	font-size: 17px;
`;

function AircraftPopup(props) {
	const {
		visible,
		saving,
		onClose,
		aircraft = {},
		onSave
	} = props;
	const globalState = useContext(store).state;
	const { userSelf } = globalState;

	const TRACKING_PARAMETERS = getTrackingParameters(userSelf);
	const groupedParametersByCategory = groupBy(TRACKING_PARAMETERS, 'category');

	const [values, setValues] = useState({
		...aircraft
	});

	function onSaveClick() {
		onSave(values);
	}

	useEffect(() => {
		setValues({
			...values,
			...aircraft
		});
	}, [aircraft, visible]);

	function calculateParametersFormula({
		data,
		fieldPrefix = ''
	}) {
		const fieldsWithFormula = TRACKING_PARAMETERS.filter(p => p.is_calculated);

		const calculations = {};

		fieldsWithFormula.forEach((field) => {
			let { formula } = field;

			field.required_parameters.forEach((key) => {
				const regex = new RegExp(`\\$${key}`, 'g');
				formula = formula.replace(regex, (data[`${fieldPrefix}${key}`] || 0));
			});

			const parsedValue = round(Parser.evaluate(formula), 4);

			calculations[`${fieldPrefix}${field.key}`] = parsedValue;
		});

		return calculations;
	}

	function getLinkedParametersData(value = 0, field) {
		let data = {};

		field.linked_parameters.forEach((lp) => {
			const otherLinkedParameters = [];

			TRACKING_PARAMETERS.forEach((param) => {
				if (param.key === lp.key || field.key === param.key) {
					return;
				}

				if (param.linked_parameters.some(linked => linked.key === lp.key)) {
					otherLinkedParameters.push(param);
				}
			});

			let updatedValue = (value * lp.multiplier);

			otherLinkedParameters.forEach((olp) => {
				const val = aircraft[`${olp.key}`] || 0;
				const { multiplier } = olp.linked_parameters.find(l => l.key === lp.key);
				updatedValue += (val * multiplier);
			});

			const newValue = updatedValue;

			data[`${lp.key}`] = newValue;

			TRACKING_PARAMETERS.forEach((tp) => {
				if (tp.id === lp.id && tp.linked_parameters.length) {
					data = { ...data, ...getLinkedParametersData(newValue, tp) };
				}
			});
		});

		return data;
	}

	function getGetFieldDiff(field) {
		const currentValue = aircraft[field.key];
		const fieldValue = values[field.key];

		if (fieldValue && currentValue !== fieldValue) {
			return Number(fieldValue) - Number(currentValue);
		}

		return 0;
	}

	function getDiffText(field) {
		const diff = getGetFieldDiff(field);
		if (diff) {
			return `${diff > 0 ? '+' : ''}${displayParameterValue(diff, field)}`;
		}

		return null;
	}

	async function onValueChange(data, param) {
		const linkedParametersData = getLinkedParametersData(data[param.key], param);
		const calculatedFormulaData = calculateParametersFormula({
			data: { ...values, ...data, ...linkedParametersData }
		});

		const newData = { ...data, ...linkedParametersData, ...calculatedFormulaData };

		setValues({ ...values, ...newData });
	}

	return (
		<Popup
			position='right'
			visible={visible}
			bodyStyle={{ width: '100%' }}
			onClose={onClose}
		>
			<ParamsWrapper>
				<Nav
					backArrow={<CloseOutline />}
					onBack={onClose}
					back={''}
					right={<Save fill='none' loading={saving} onClick={onSaveClick}>Save</Save>}
				>
					Update status
				</Nav>
				<ContentWrapper>
					<Overview>
						<List>
							{Object.keys(groupedParametersByCategory)
								.filter(key => groupedParametersByCategory[key].some(param => isEnabledParameter(aircraft.enabled_parameters, param.key)))
								.map((key) => {
									return <Fragment key={key}>
										{groupedParametersByCategory[key].map((param) => {
											if (isEnabledParameter(aircraft.enabled_parameters, param.key)) {
												return <InputComponent
													onSave={data => onValueChange(data, param)}
													key={param.key}
													min={0}
													disabled={param.is_calculated}
													dataKey={`${param.key}`}
													title={param.name}
													description={getDiffText(param)}
													digits={param.precision}
													type={param.type === 'hours' ? 'hours' : 'stepper'}
													initialValue={values[`${param.key}`] || 0}
												/>;
											}

											return null;
										})}
									</Fragment>;
								})}
						</List>
					</Overview>
				</ContentWrapper>
			</ParamsWrapper>
		</Popup>
	);
}

export default AircraftPopup;

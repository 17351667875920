import React, { useState, useContext, useEffect, Fragment } from 'react';
import { NavBar, Tabs, List, Skeleton, ErrorBlock, Badge, Button, Dialog, Toast, ProgressCircle } from 'antd-mobile';
import styled from 'styled-components';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { deleteFlightLog, getAircraft, getAircraftChecklists, getFlightLogItem, getList, updateFlightLogItem } from '../api';
import { store } from '../utils/store';
import InputComponent from '../components/Input';
import Files from './Files';
import Notes from './Notes';
import { featureEnabled, matchesPermission } from '../utils/permission-helpers';
import { getTrackingParameters, isEnabledParameter, getCustomFieldValues, convertHoursAndMinutes, isUUID } from '../utils/helpers';
import ChecklistPopup from '../components/ChecklistPopup';
import Defects from './Defects';


const LogbookItemWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;
const ContentWrapper = styled.div`
	flex: 1;
	min-height: 0;
	overflow:  auto;
	width: 100%;
	background: #f0f2f5;
`;

const Overview = styled.div`
	padding: 12px 0px;
`;

const LogbookInfo = styled.div`
	padding: 0px 12px;
	background: #fff;

	h2 {
		margin: 0px 8px 0px 0px;
		display: inline-block;
		color: var(--adm-color-text);
	}

	.adm-ellipsis {
		a {
			margin-left: 8px;
		}
	}
`;

const NotFound = styled(ErrorBlock)`
	height: 100%;
	padding: 12px;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
`;

const Progress = styled(ProgressCircle)`
	font-size: 12px;
	padding-top: 8px;
`;

const Nav = styled(NavBar)`
	background: #fff;
	display: flex;
	border-bottom: solid 1px var(--adm-color-border);
`;

const Middot = styled.span`
	padding: 0px 4px;
`;

const ButtonWrapper = styled.div`
	margin-top: 12px;
	padding: 12px;
`;

const TabBar = styled(Tabs)`
	display: flex;
	flex-direction: column;
	height: 100%;
	.adm-tabs-header {
		background: #fff;
	}

	.adm-tabs-content {
		flex: 1;
		overflow: auto;
		padding: 0;
	}
`;

const ToastContent = styled.div`
	word-break: normal;
	text-align: center;
	width: 100%;
`;

function LogbookItem() {
	const params = useParams();
	const history = useHistory();
	const { id, aircraftId } = params;
	const globalState = useContext(store).state;
	const { userSelf } = globalState;
	const [loading, setLoading] = useState(false);
	const [saving, setSaving] = useState(false);
	const [showChecklistPopup, setShowChecklistPopup] = useState(false);
	const [selectedChecklist, setSelectedChecklist] = useState({});
	const [err, setErr] = useState(false);
	const [record, setRecord] = useState({});
	const [aircraftChecklists, setAircraftChecklists] = useState([]);
	const [users, setUsers] = useState([]);
	const [aircraft, setAircraft] = useState({});

	const TRACKING_PARAMETERS = getTrackingParameters(userSelf);
	const groupedParametersByCategory = groupBy(TRACKING_PARAMETERS, 'category');

	async function fetchRecord(silent) {
		if (!silent) {
			setLoading(true);
		}

		try {
			const data = await getFlightLogItem(aircraftId, id);
			setRecord(data);
		} catch (e) {
			setErr(true);
		}

		setLoading(false);
	}

	async function fetchUsers() {
		if (featureEnabled(userSelf, 'staff_enabled')) {
			const usersData = await getList('users').catch();

			if (usersData) {
				setUsers(usersData);
			}
		}
	}

	async function fetchAircraft() {
		setLoading(true);
		try {
			const data = await getAircraft(aircraftId);
			setAircraft(data);
		} catch (e) {
			setErr(true);
		}
		setLoading(false);
	}

	async function fetchAircraftChecklists(silent) {
		if (!silent) {
			setLoading(true);
		}

		try {
			const data = await getAircraftChecklists(`?aircraft_id=${aircraftId}`);
			setAircraftChecklists(data);
		} catch (e) {
			setErr(true);
		}

		setLoading(false);
	}

	useEffect(() => {
		fetchRecord();
		fetchAircraft();
		fetchAircraftChecklists();
		fetchUsers();
	}, []);

	function getLinkedParametersData(value = 0, field) {
		let data = {};

		field.linked_parameters.forEach((lp) => {
			const otherLinkedParameters = [];

			TRACKING_PARAMETERS.forEach((param) => {
				if (param.key === lp.key || field.key === param.key) {
					return;
				}

				if (param.linked_parameters.some(linked => linked.key === lp.key)) {
					otherLinkedParameters.push(param);
				}
			});

			let updatedValue = (value * lp.multiplier);

			otherLinkedParameters.forEach((olp) => {
				const val = record[`current_${olp.key}`] || 0;
				const { multiplier } = olp.linked_parameters.find(l => l.key === lp.key);
				updatedValue += (val * multiplier);
			});

			const newValue = updatedValue;

			data[`current_${lp.key}`] = newValue;

			TRACKING_PARAMETERS.forEach((tp) => {
				if (tp.id === lp.id && tp.linked_parameters.length) {
					data = { ...data, ...getLinkedParametersData(newValue, tp) };
				}
			});
		});


		return data;
	}

	function calculateDurationHours(takeoffTime, takeoffDate, landingTime, landingDate) {
		if (takeoffTime && takeoffDate && landingTime && landingDate) {
			const takeoff = moment(`${takeoffDate.format('YYYY-MM-DD')} ${takeoffTime.format('HH:mm')}`);
			const landing = moment(`${landingDate.format('YYYY-MM-DD')} ${landingTime.format('HH:mm')}`);

			const difference = landing.diff(takeoff);
			const duration = moment.duration(difference);
			const currHours = Math.trunc(duration.asHours());
			const currMinutes = Number(moment.utc(difference).format('mm'));

			const value = convertHoursAndMinutes(currHours, currMinutes);

			const defaultHoursParameters = TRACKING_PARAMETERS.filter(p => p.is_default_flight_hours);

			let calculatedData = {};

			defaultHoursParameters.forEach((field) => {
				calculatedData[`current_${field.key}`] = value;
				calculatedData = { ...calculatedData, ...getLinkedParametersData(value, field) };
			});

			return calculatedData;
		}

		return {};
	}

	async function updateRecord(data, param) {
		let payload = data;
		payload.id = record.id;

		if ((get(userSelf.settings, 'custom_utilization_fields') || []).some(f => f.id in payload)) {
			const customData = {
				...(record.custom_fields_data || {}),
				...getCustomFieldValues(payload, get(userSelf.settings, 'custom_utilization_fields') || [])
			};

			payload =
				{ ...{ custom_fields_data: customData }, ...payload };
		}

		if ('pic_id' in payload && !isUUID(payload.pic_id)) {
			payload.pic_name = payload.pic_id;
			payload.pic_id = null;
		}

		if ('sic_id' in payload && !isUUID(payload.sic_id)) {
			payload.sic_name = payload.sic_id;
			payload.sic_id = null;
		}


		if (Object.keys(payload).some(key => ['date_lnd', 'time_lnd', 'date_toff', 'time_toff'].includes(key))) {
			if ((payload.date_toff || record.date_toff) && (payload.date_lnd || record.date_lnd)) {
				const takeoffTime = moment(payload.time_toff || record.time_toff, 'HH:mm:ss');
				const takeoffDate = moment(payload.date_toff || record.date_toff);
				const landingTime = moment(payload.time_lnd || record.time_lnd, 'HH:mm:ss');
				const landingDate = moment(payload.date_lnd || record.date_lnd);

				payload = {
					...payload,
					...calculateDurationHours(takeoffTime, takeoffDate, landingTime, landingDate)
				};
			}
		}

		if (param) {
			payload = { ...payload, ...getLinkedParametersData(payload[`current_${param.key}`], param) };
		}


		const respData = await updateFlightLogItem(aircraftId, payload);

		setRecord(respData);
	}

	async function onChecklistChange(data) {
		setSaving(true);
		const payload = data;
		payload.id = record.id;

		try {
			const respData = await updateFlightLogItem(aircraftId, payload);
			setRecord(respData);
		} catch (e) {
			Toast.show({
				icon: 'fail',
				content: <ToastContent>
					Something went wrong, cannot save checklist.
				</ToastContent>
			});
		}

		setSaving(false);
	}

	async function onDelete() {
		const result = await Dialog.confirm({
			cancelText: 'Cancel',
			confirmText: 'Yes',
			style: { textAlign: 'center' },
			content: 'Delete this logbook entry?'
		});

		if (result) {
			setSaving(true);
			await deleteFlightLog(aircraftId, record.id).catch(() => {
				Toast.show({
					icon: 'fail',
					content: <ToastContent>
						Something went wrong, cannot delete logbook entry.
					</ToastContent>
				});
			});

			setSaving(false);
			history.push(`/aircraft/${aircraftId}`);
		}
	}

	function getOpenDefectsCount() {
		return record.aircraft_defects?.length ?
			record.aircraft_defects.filter(d => ['past_due', 'near_due', 'open'].includes(d.status)).length : 0;
	}


	return (err ? <NotFound
		title="Logbook"
		description="This logbook record does not exist, or you dont have rights to view it"
		status='empty'
	/> : <LogbookItemWrapper>
		<Nav onBack={() => history.goBack()} back={''}>
			Logbook entry
		</Nav>
		<LogbookInfo>
			{loading ? <Skeleton.Paragraph lineCount={3} animated/> :
				<List.Item
					key={record.id}
					title={<Fragment>
						<span>{`${record.date_toff ? moment(record.date_toff).format(get(userSelf.settings, 'date_format')) : ''}`}</span>
						{record.flight && <Middot>&middot;</Middot>}
						<span>{record.flight}</span>
						{record.flight_type && <Middot> &middot;</Middot>}
						<span>{record.flight_type}</span>
					</Fragment>}
					clickable={false}
				>
					<Fragment>
						<div><h2>{record.from}</h2> <h2>-</h2> <h2>{record.to}</h2></div>
						<div><span>{`${(record.time_toff ? moment(record.time_toff, 'HH:mm:ss').format('HH:mm') : '')}`}</span> - <span>{`${(record.time_lnd ? moment(record.time_lnd, 'HH:mm:ss').format('HH:mm') : '')}`}</span></div>
					</Fragment>
				</List.Item>
			}
		</LogbookInfo>
		<ContentWrapper>
			<TabBar>
				<Tabs.Tab
					title={'Details'}
					key='details'
				>
					<Overview>
						<List header="Details">
							<InputComponent
								onSave={updateRecord}
								disabled={!matchesPermission(userSelf, 'edit_utilization')}
								dataKey="tlog"
								title="TLog"
								initialValue={record.tlog}
							/>
							<InputComponent
								onSave={updateRecord}
								disabled={!matchesPermission(userSelf, 'edit_utilization')}
								dataKey="flight"
								title="Flight number"
								initialValue={record.flight}
							/>
							<InputComponent
								onSave={updateRecord}
								disabled={!matchesPermission(userSelf, 'edit_utilization')}
								dataKey="flight_type"
								title="Flight type"
								type="select"
								options={(get(userSelf, 'settings.flight_types') || []).map(t => ({ label: t, value: t }))}
								initialValue={[record.flight_type]}
							/>
							<InputComponent
								onSave={updateRecord}
								disabled={!matchesPermission(userSelf, 'edit_utilization')}
								dataKey="from"
								title="From"
								type="select"
								options={(get(userSelf, 'settings.airports') || []).map(t => ({ label: t, value: t }))}
								initialValue={[record.from]}
							/>
							<InputComponent
								onSave={updateRecord}
								disabled={!matchesPermission(userSelf, 'edit_utilization')}
								dataKey="to"
								title="To"
								type="select"
								options={(get(userSelf, 'settings.airports') || []).map(t => ({ label: t, value: t }))}
								initialValue={[record.to]}
							/>
							{get(userSelf.settings, 'custom_utilization_fields') && get(userSelf.settings, 'custom_utilization_fields').map(field => (
								<InputComponent
									onSave={updateRecord}
									key={field.id}
									disabled={!matchesPermission(userSelf, 'edit_utilization')}
									dataKey={`${field.id}`}
									title={field.title}
									initialValue={get(record, `custom_fields_data.${field.id}`)}
								/>
							))}
						</List>
						<List header="Flight crew">
							<InputComponent
								onSave={updateRecord}
								disabled={!matchesPermission(userSelf, 'edit_utilization')}
								dataKey="pic_id"
								title="Pilot in Command"
								placeholder={'Type or select name'}
								type="autocomplete"
								options={[...[{ label: 'None/Guest', value: null }], ...users.map(u => ({ label: u.name, value: u.id }))]}
								defaultValue={{
									label: userSelf.name,
									key: userSelf.id
								}}
								initialValue={record.pic_name ? {
									label: record.pic_name,
									key: record.pic_id
								} : null}
							/>
							<InputComponent
								onSave={updateRecord}
								disabled={!matchesPermission(userSelf, 'edit_utilization')}
								dataKey="sic_id"
								title="Second In Command"
								placeholder={'Type or select name'}
								type="autocomplete"
								options={[...[{ label: 'None', value: null }], ...users.map(u => ({ label: u.name, value: u.id }))]}
								initialValue={{
									label: record.sic_name,
									key: record.sic_id
								}}
							/>
						</List>
						{!!aircraftChecklists.length && <List header="Checklists">
							{aircraftChecklists.map((checklist) => {
								let progress = <Progress percent={0}>N/A</Progress>;

								const checklistData = get(record, `checklist_data.${checklist.id}`) || {};
								const checkedCount = Object.keys(checklistData.checked || {}).length;
								const done = checkedCount === checklist.checklist.length;

								const precent = (checkedCount / checklist.checklist.lenght) * 100;
								progress = <Progress
									percent={precent}
									style={{
										'--fill-color': done ? 'var(--adm-color-success)' : 'var(--adm-color-primary)'
									}}
								>
									{ done ? 'OK' : `${checkedCount}/${checklist.checklist.length}`}
								</Progress>;

								return <List.Item
									extra={progress}
									key={checklist.id}
									onClick={() => {
										setSelectedChecklist(checklist);
										setShowChecklistPopup(true);
									}}
									clickable
								>
									{checklist.title || '-'}
								</List.Item>;
							})}
						</List>}
						<List header="Time">
							<InputComponent
								onSave={updateRecord}
								disabled={!matchesPermission(userSelf, 'edit_utilization')}
								dataKey="date_off_block"
								secondKey="time_off_block"
								title="Off-block"
								max={record.date_off_block ? new Date(`${moment(record.date_off_block).format('YYYY-MM-DD')}T${record.time_off_block}`) : undefined}
								type="datetime"
								initialValue={record.date_off_block ? new Date(`${moment(record.date_off_block).format('YYYY-MM-DD')}T${record.time_off_block}`) : null}
							/>
							<InputComponent
								onSave={updateRecord}
								disabled={!matchesPermission(userSelf, 'edit_utilization')}
								dataKey="date_toff"
								secondKey="time_toff"
								max={record.date_lnd ? new Date(`${moment(record.date_lnd).format('YYYY-MM-DD')}T${record.time_lnd}`) : undefined}
								title="Takeoff"
								type="datetime"
								initialValue={record.date_toff ? new Date(`${moment(record.date_toff).format('YYYY-MM-DD')}T${record.time_toff}`) : null}
							/>
							<InputComponent
								onSave={updateRecord}
								disabled={!matchesPermission(userSelf, 'edit_utilization')}
								dataKey="date_lnd"
								secondKey="time_lnd"
								min={record.date_toff ? new Date(`${moment(record.date_toff).format('YYYY-MM-DD')}T${record.time_toff}`) : undefined }
								title="Landing"
								type="datetime"
								initialValue={record.date_lnd ? new Date(`${moment(record.date_lnd).format('YYYY-MM-DD')}T${record.time_lnd}`) : null}
							/>
							<InputComponent
								onSave={updateRecord}
								disabled={!matchesPermission(userSelf, 'edit_utilization')}
								dataKey="date_on_block"
								secondKey="time_on_block"
								title="On-block"
								min={record.date_lnd ? new Date(`${moment(record.date_lnd).format('YYYY-MM-DD')}T${record.time_lnd}`) : undefined }
								type="datetime"
								initialValue={record.date_on_block ? new Date(`${moment(record.date_on_block).format('YYYY-MM-DD')}T${record.time_on_block}`) : null}
							/>
						</List>
						<List header="Durations/Counts">
							{Object.keys(groupedParametersByCategory)
								.filter(key => groupedParametersByCategory[key].some(param => isEnabledParameter(aircraft.enabled_parameters, param.key)))
								.map((key) => {
									return <Fragment key={key}>
										{groupedParametersByCategory[key].map(param => (
											isEnabledParameter(aircraft.enabled_parameters, param.key) && <InputComponent
												onSave={payload => updateRecord(payload, param)}
												key={param.key}
												min={0}
												disabled={!matchesPermission(userSelf, 'edit_utilization') || param.is_calculated}
												dataKey={`current_${param.key}`}
												title={param.name}
												digits={param.precision}
												type={param.type === 'hours' ? 'hours' : 'stepper'}
												initialValue={record[`current_${param.key}`] || 0}
											/>))}
									</Fragment>;
								})}
						</List>
						<List header="Additional information">
							<InputComponent
								placeholder="Remarks"
								onSave={updateRecord}
								disabled={!matchesPermission(userSelf, 'edit_utilization')}
								dataKey="remarks"
								initialValue={record.remarks}
								title="Remarks"
								type="text"
							/>
						</List>
						{matchesPermission(userSelf, 'delete_utilization') && <ButtonWrapper>
							<Button block saving={saving} onClick={onDelete} color="danger" fill="outline">Delete</Button>
						</ButtonWrapper>}
						<ChecklistPopup
							visible={showChecklistPopup}
							checklistData={record.checklist_data || {}}
							disabled={record.id ? record.user_id !== userSelf.id : false}
							checklist={selectedChecklist}
							saving={saving}
							onChange={onChecklistChange}
							onClose={() => setShowChecklistPopup(false)}
						/>
					</Overview>
				</Tabs.Tab>
				{featureEnabled(userSelf, 'utilization_enabled') && matchesPermission(userSelf, 'view_aircraft_defects') &&
					<Tabs.Tab
						title={<Badge content={getOpenDefectsCount() || null} style={{ '--right': '-10px', '--top': '4px' }}>
							Defects
						</Badge>}
						key="defects"
					>
						<Defects aircraft={aircraft} logId={record.id} logReference={record.tlog} />
					</Tabs.Tab>
				}
				<Tabs.Tab
					title={<Badge
						content={record.files_count || null}
						style={{ '--color': 'var(--adm-color-primary)', '--right': '-10px', '--top': '4px' }}>
							Files
					</Badge>}
					key='files'
				>
					<Files
						onChange={() => fetchRecord(true)}
						objectType="utilization"
						disabled={!matchesPermission(userSelf, 'manage_files_notes_utilization')}
						objectId={record.id}
					/>
				</Tabs.Tab>
				<Tabs.Tab
					title={<Badge
						content={record.notes_count || null}
						style={{ '--color': 'var(--adm-color-primary)', '--right': '-10px', '--top': '4px' }}>
							Notes
					</Badge>}
					key='notes'
				>
					<Notes
						onChange={() => fetchRecord(true)}
						objectType="utilization"
						disabled={!matchesPermission(userSelf, 'manage_files_notes_utilization')}
						objectId={record.id}
					/>
				</Tabs.Tab>
			</TabBar>
		</ContentWrapper>
	</LogbookItemWrapper>);
}

export default LogbookItem;

import React, { useContext, Fragment, useState } from 'react';
import { List, SpinLoading, ErrorBlock, Ellipsis } from 'antd-mobile';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { get, truncate } from 'lodash';
import styled from 'styled-components';
import { store } from '../utils/store';
import { matchesPermission } from '../utils/permission-helpers';

const Records = styled(List)`
	width: 100%;
	padding: 12px 0px;
	.adm-list-item-content-extra {
		display: flex;
	}
`;

const SpinWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`;

function LogbookList(props) {
	const {
		records,
		loading
	} = props;
	const globalState = useContext(store).state;
	const history = useHistory();
	const { userSelf } = globalState;

	function getLogbookList() {
		if (!records.length) {
			return <ErrorBlock
				style={{ paddingTop: 12 }}
				description={null}
				title={'No records added'}
				status='empty'
			/>;
		}

		return <Records>
			{records.map((record) => {
				return <List.Item
					key={record.id}
					title={<Fragment>
						<span>{`${record.date_toff ? moment(record.date_toff).format(get(userSelf.settings, 'date_format')) : ''}`}</span>
						{record.pic_name && <span> &middot; </span>}
						{record.pic_name && <span>{record.pic_name}</span>}
						{record.flight_type && <span> &middot; </span>}
						<span>{record.flight_type}</span>
					</Fragment>}
					description={
						<Ellipsis direction='end' rows={2} content={record.remarks ? `Remarks: ${record.remarks}` : ''} />
					}
					extra={truncate(record.flight, { length: 12 })}
					onClick={() => history.push(`/aircraft/${record.aircraft_id}/logbook/${record.id}`)}
					clickable={matchesPermission(userSelf, 'edit_utilization')}
				>
					<Fragment>
						<div><span>{record.from}</span> - <span>{record.to}</span></div>
						<div><small><span>{`${(record.time_toff ? moment(record.time_toff, 'HH:mm:ss').format('HH:mm') : '')}`}</span> - <span>{`${(record.time_lnd ? moment(record.time_lnd, 'HH:mm:ss').format('HH:mm') : '')}`}</span></small></div>
					</Fragment>
				</List.Item>;
			})}
		</Records>;
	}

	return (
		loading ? <SpinWrapper><SpinLoading style={{ '--size': '48px' }} /></SpinWrapper> : <Fragment>
			{getLogbookList()}
		</Fragment>
	);
}

export default LogbookList;
